.modal {
  :global(.ant-modal-content) {
    width: 100% !important;
    height: 100% !important;
    background-color: inherit !important;
    box-shadow: none !important;
  }

  &:global(.ant-modal) {
    width: 80% !important;
    height: 80% !important;
    user-select: none;
  }

  :global(.ant-modal-body) {
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
    height: 100% !important;
  }

  .prev,
  .next {
    min-width: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    &:hover {
      color: #fff;
    }
  }

  .modalWrapper {
    display: flex;
    justify-content: space-between;
  }
  .imgModal {
    max-width: calc(100% - 200px);
    object-fit: contain;
  }
  .arrow {
    font-size: 40px;
  }
  :global(.ant-modal-body) {
    justify-content: center !important;
    width: max-content;
  }
  :global(.ant-modal-content) {
    width: max-content;
  }
  :global(.ant-modal) {
    display: flex !important;
    justify-content: center !important;
  }
}

.imgContainer {
  display: block;
	position: relative; 
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .4);
    opacity: 0;
    transition: opacity 0.3s linear;
  };

  &:hover::before {
    opacity: 1;
  }

}

.img {
  max-width: 100%;
  cursor: pointer;
}
