@import "../../styles/variables.less";

.regular_block {
  background-color: #fff;
  padding: 32px 32px 25px 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
  &.centered {
    align-items: center;
  }
  .heading {
    font-size: 1.14rem;
    font-weight: 300;
    line-height: 1.3;
    width: 70%;
    margin-bottom: 32px;
  }

  .label {
    line-height: 1.2;
    font-weight: 500;

    &.req::after {
      content: "*";
    }
  }
  input,
  textarea {
    background-color: @grey4 !important;
    &:hover,
    &:focus {
      border-color: @grey;
      box-shadow: none;
    }
  }
  .radio_group {
    display: flex;
    justify-content: space-between;

    .radio_btn {
      position: relative;

      &::before {
        content: "";
        width: 24px;
        height: 24px;
        background-color: @grey4;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        left: 12px;
      }

      &::after {
        content: "";
        width: 14px;
        height: 14px;
        background-color: @blackBlue;
        border-radius: 50%;
        position: absolute;
        top: 17px;
        left: 17px;
        transition: 0.3s;
        transform: scale(0);
      }

      &.active::after {
        transform: scale(1);
      }

      &:first-child {
        border-right: 1px solid @grey2;
      }

      box-shadow: none;
      border: 1px solid @grey2;
      padding: 12px 12px 20px 48px;
      height: auto;
      display: flex;
      flex-direction: column;
      width: calc((100% / 3) - 24px);

      span[ant-radio-button]:focus {
        box-shadow: none;
      }

      .title {
        display: block;
        font-size: 1.14rem;
        font-weight: 600;
        line-height: 1.2;
        color: @general;
      }

      .subtitle {
        display: block;
        // font-size: 1.14rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2;
        color: @grey3;
      }
    }
  }


  .submit {
    background: #ea5b0c;
    padding: 0 40px;
    font-weight: 300;
    line-height: 16px;
    font-size: 14px;
    color: #fff;
  }
}

.usd_number_form_input::-webkit-outer-spin-button,
.usd_number_form_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.usd_number_form_input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}
.captcha {
  border: 1px solid #e8edf0;

  div {
    height: 40px;
    display: flex;
    align-items: center;
  }
}
.TextArea {
  background-color: #F5F7F8; 
  min-height: 100px !important;
  textarea {
    min-height: 100px;
  }
}