@import "Styles/variables.less";

.wrapper {
  :global {
    .ant-input::placeholder,
    .ant-select-selection-placeholder,
    .ant-picker-input > input::placeholder,
    .ant-input[disabled] {
      color: grey !important;
      font-weight: 300;
    }
    .ant-form.ant-form-horizontal {
      margin-top: 0px;
    }
    .ant-select-selection-search-input {
      /* width: 100%; */
      min-width: 120px;
    }
    .ant-row .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-form-item {
      /* Default styles for the form item */

      &.ant-form-item-has-error {
        textarea {
          &:hover,
          &:focus {
            box-shadow: none !important;
            border-color: transparent;
            .TextArea {
              border-right-width: 0px !important;
            }
          }
        }
      }

      &:not(.ant-form-item-has-error) {
        textarea {
          &:focus,
          &:hover {
            box-shadow: none !important;
            border-right-width: 0px !important;
          }
        }
      }
    }
    .ant-picker-time-panel-column::after {
      height: unset !important;
      display: none;
    }
    .ant-input {
      border: none !important;
    }
    .ant-form-item-has-error .ant-input,
    .ant-form-item-has-error .ant-select-selector {
      border: 1px solid #ff4d4f !important;
    }
    .ant-form-item-has-error .ant-picker-input input {
      border: none;
    }
  }
  @media (min-width: 2543px) {
    width: 1741px;
  }
}
.modal {
  width: min-content !important;
  &_finishModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    & > p {
      margin-top: 0px !important;
      font-size: 18px;
    }
    & > h2 {
      font-size: 18px;
    }
  }
  &_exist {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.saved_modal {
  width: 837px;
  text-align: center;
  & > p {
    margin-top: 15px !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  & > h3 {
    font-size: 19px !important;
  }
  & > button {
    margin: auto;
    margin-top: 20px;
  }
}

.textInput.textInput {
  width: 100%;
  height: 100%;
  background: white;
  background-color: white !important;
  border: none;
  resize: none;
}
.status {
  display: flex;
  align-items: center;
  gap: 0px;
}
.formItem {
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  :global {
    .ant-form-item-control-input-content,
    .ant-form-item-control-input {
      height: auto !important;
    }
  }
}
.datePicker {
  background: white !important;
  background-color: white !important;
  :global {
    input {
      background: white !important;
      background-color: white !important;
    }
    .ant-picker.ant-picker-disabled {
      background: white !important;
      background-color: white !important;
    }
  }
}
.container {
  overflow: auto;
  .tableRow {
    .selector {
      :global {
        .ant-select-selector.ant-select-selector,
        .ant-select-selection-search-input {
          background-color: #fff !important;
          background: white !important;
        }
      }
    }
    .tableBody {
      .timeInput {
        border: none;
        background-color: transparent !important;
        width: 76px;
        padding: 0;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
.countrySelect.countrySelect {
  width: 120px !important;
  :global {
    .ant-select-selector.ant-select-selector {
      background-color: white !important;
      border-radius: 0 !important;
      background: white !important;
      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.tableRow {
  width: 1754px;
  display: flex;
  flex-direction: column;
  border: none;
}
.head {
  flex-shrink: 0;
  background: #f5f7f8;
  display: flex;
  width: 1740px;
  .num {
    font-size: 18px;
  }
  .cell {
    align-items: center;
  }
}
.cell {
  padding: 5px 16px;
  color: #474747;
  font-weight: 600;
  display: flex;
  flex-shrink: 0;
  //flex-grow: 1;
  background: #f5f7f8;
  white-space: break-spaces;
  transition: 0.3s;
}
.cell2 {
  padding: 24px 16px;
  flex-shrink: 0;
  transition: 0.3s;
  &.grey {
    background: #f2f2f2;
  }
}
.tableBody {
  display: flex;
}
.stand {
  display: flex;
  .standLeft {
    width: max-content;
    display: flex;
  }
  .standImg {
    max-width: 100%;
  }
  .standRight {
    min-height: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    border-top: 1px solid #e8edf0;
    border-bottom: none;
    border-right: none;
    display: flex;
    .standDescription {
      // padding: 24px 10px 0px 16px;
      transition: 0.3s;
    }
    .controls {
      border-left: 1px solid #e8edf0;
      display: flex;
      flex-direction: column;
      .checkboxes {
        padding: 24px 16px;
      }
      .actions {
        // margin-top: auto;
        // border-top: 1px solid #e8edf0;
        // padding: 20px 16px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        border-top: 1px solid #e8edf0;
        margin-top: 96%;
        .wrap {
          margin: 20px 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
        .saveBtn,
        .moderate {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 8px;
          font-weight: 500;
          width: 29px;
          cursor: pointer;
        }
        .saveBtn {
          background: @grey4;
          background-color: @grey4;
          border: none;
          outline: none;
        }
        .moderate {
          background: #ffd5bd;
        }
      }
    }
  }
}
.small {
  width: 48px;
}
.premid {
  width: 120px;
}
.mid {
  width: 120px;
}
.big {
  width: 160px;
}
.xl {
  width: 300px;
}

.fieldError {
  border: 1px solid #ff4d4f !important;

  // &:hover:focus {
  //   border: 1px solid #ff4d4f !important;
  // }
}
.isPrivate {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;

  & > h3 {
    text-align: center !important;
    font-size: 18px;
    margin-bottom: 7px;
  }

  & > p {
    margin-top: 0px !important;
    text-align: center !important;
    font-weight: 400 !important;
    width: 600px;
  }
  & a {
    color: @orange;
    &:hover {
      color: @linkBlue;
    }
  }
}

.TextArea,
.TextArea,
.TextArea textarea {
  max-height: initial;
  padding: 10px;
  border: 0px solid transparent;
  background-color: transparent !important;
  background: transparent !important;
  width: 100%;
  height: 202px;
  background: white;
  background-color: white !important;
  border: none;
  resize: none;
  padding: 0;
  &::placeholder {
    font-weight: 300;
  }

  &.greenPlaceholder {
    &::placeholder {
      color: #249f5d;
    }
  }
}

.tableBody .withDropDown {
  z-index: 1;
  position: relative;
  .dropDown {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 24px 16px;
    background: #f2f2f2;
    box-shadow: -1px 15px 34px -10px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -1px 15px 34px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 15px 34px -10px rgba(0, 0, 0, 0.75);
  }
}
.uploadText {
  color: grey;
  font-weight: 300;
  cursor: pointer;
  &.error {
    color: #ff4d4f;
  }
}

.adminLink {
  color: #ea5b0c;
  &:hover {
    color: #1890ff;
  }
}
