.Textarea {
  // background-color: #f5f7f8 !important;
  min-height: 140px !important;
  overflow: visible !important;

  textarea {
    min-height: 140px !important;
    background-color: #f5f7f8 !important;
    overflow: hidden !important;
  }
  &_p20 {
    padding: 20px !important;
  }
  &_h100 {
    min-height: 100px !important;
    textarea {
      min-height: 100px !important;
    }
  }
  &_h40 {
    min-height: 40px !important;
    textarea {
      padding: 8px !important;
      min-height: 40px !important;
    }
  }
}
