@import './variables';

.grey{
    color: @grey5;
}
.red{
    color: @red;
}
.orange{
    color: @orange;
}
.green{
    color: @green;
}
.darkRed{
    color: #B81C1C;
}
